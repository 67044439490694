import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

function Privacy() {
  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#87ddfd',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          backgroundColor: '#f3f6fb',
          padding: '50px'
        }}
      >

      
      <h1 style={{ textAlign: 'center', width: '100%' }}>PuppyScramble! is coming to the AppStore soon!</h1>
      <p style={{ textAlign: 'center', width: '100%' }}>
        (we're focussing on getting the game right, so we apologize for the look of this page)
      </p>
     <Grid container spacing={2} style={{
      width: '100%',
      backgroundColor: '#f3f6fb'
     }}>
        <Grid item xs={12} md={12}>
          <Grid style={{
            width: '100%',
            textAlign: 'center',
          }}>
            <h3>Our Privacy Policy</h3>
            <div
              style={{
                textAlign: 'left'
              }}
            >
            <p>We only collect data from network requests your device makes to our servers for gameplay information. If you have any questions, please feel free to reach out to <a href="mailto:support@puppyscramble.com?subject=Puppo%20Submission!">support@puppyscramble.com</a>. We do ask that you follow a few guidelines</p>
            <ul>
              <li>This data can NOT be used to indentify you (we do NOT track your name or likeness in any way).</li>
              <li>We ONLY track network requests for gameplay information. This helps us know if the app has any issues.</li>
              <li>If you have any questions, please reach out!</li>
            </ul>
            </div>
          </Grid>
        </Grid>
     </Grid>
     </div>
    </div>
  )
}

export default Privacy;
