import React from 'react';
import Home from './Home';
import Privacy from './Privacy';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App(){

  return (
    <Router>
      <Routes>
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  )
}

export default App;