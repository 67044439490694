import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

function Home() {
  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#87ddfd',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          backgroundColor: '#f3f6fb',
          padding: '50px'
        }}
      >

      
      <h1 style={{ textAlign: 'center', width: '100%' }}>PuppyScramble! is coming to the AppStore soon!</h1>
      <p style={{ textAlign: 'center', width: '100%' }}>
        (we're focussing on getting the game right, so we apologize for the look of this page)
      </p>
     <Grid container spacing={2} style={{
      width: '100%',
      backgroundColor: '#f3f6fb'
     }}>
        <Grid item xs={12} md={6}>
          <Grid style={{
            width: '100%',
            textAlign: 'center',
          }}>
            <h3>Want to feature your dog or brand?</h3>
            <div
              style={{
                textAlign: 'left'
              }}
            >
            <p>We're always taking suggestions! Send an email with your favorite dog pics to <a href="mailto:submissions@puppyscramble.com?subject=Puppo%20Submission!">submissions@puppyscramble.com</a>. We do ask that you follow a few guidelines</p>
            <ul>
              <li>Please send pictures of ONLY animals. We will not accept photos with any humans in them.</li>
              <li>Please include your dog's name, breed and the location of the photos.</li>
              <li>If you have a social media handle you want us to promote, send that too!</li>
            </ul>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
        <Grid style={{
            width: '100%',
            textAlign: 'center',
          }}>
            <h3>Have something to report?</h3>
            <div
              style={{
                textAlign: 'left'
              }}
            >
            <p>PuppyScramble! is still a work in progress Send an email with any issues, comments or suggestions to <a href="mailto:support@puppyscramble.com">support@puppyscramble.com</a></p>
            <ul>
              <li>Found a bug? Let us know!</li>
              <li>Have any thing else? Give us a howl!</li>
              <li>Think our dog puns need a bit more work? Bark at us!</li>
              <li>Think we should do this with other animals?!? Our ears our listening!</li>
            </ul>
            </div>
          </Grid>
        </Grid>
     </Grid>
     </div>
    </div>
  )
}

export default Home;
